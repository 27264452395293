import React from 'react';
import './RenderImg.css'

const RenderImg = ({ imgUrl, box }) => {
    return (
        <div className='center-flex ma'>
            <div className='absolute mt2'>
                <img id='renderImg' alt='' src={imgUrl} width='500px' height='auto' />
                <div className='bounding-box' style={{ top: box.topRow, right: box.rightCol, bottom: box.bottomRow, left: box.leftCol }}></div>
            </div>
        </div>
    );
}

export default RenderImg;